import { Link } from "gatsby-plugin-intl"
import React, { useState } from "react"
import ReactModal from "react-modal"
import "swiper/css/swiper.css"
import PricingCard from "../../components/card"
import SignUpForm from "../../components/sign-up-form"
import Switch from "../../components/switch"
import { useAnalytics } from "../../hooks/useTracking"
import { SignUpModalStyles } from "../../styles/modal"
import { useTranslation } from "../../utils"
import "./styles.css"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { CONFIG } from "../../config"
import classNames from "classnames"

ReactModal.setAppElement("#___gatsby")

const singleIncluded = [
  "1 Site",
  "Antivirus",
  "Web application Firewall",
  "SSL monitoring",
  "Open ports & path scanner",
  "Availability monitoring",
  "Wordpress, Plesk, Joomla, Drupal",
]

const standardIncluded = [
  "3 Site",
  "Everything in Single",
  "Website security scoring",
  "Blacklist monitoring",
  "Technology & Vulnerability scanner",
  "IP Reputation",
  "Server Resources monitoring",
]

const professionalIncluded = [
  "Up to 50 websites",
  "Everything in Standard",
  "Central monitoringd dashboard",
  "Whitelabel PDF reports",
  "Up to 3 team members",
  "De-blacklisting",
  "High priority event support",
]

const stripePromise = loadStripe(CONFIG?.STRIPE_TOKEN)

const PricingSection = () => {
  const { t } = useTranslation()
  const [cycle, setCycle] = useState("monthly")
  const [isModalOpen, setModalOpen] = useState(false)
  const [pickedTariff, setPickedTariff] = useState(null)
  const [tab, setTab] = useState(1)
  const analytics = useAnalytics()

  return (
    <section className="pricing-section">
      <div className="container">
        <h5>PRICING</h5>
        <div className="pricing-section__header">
          <h2 className="pricing-section__title">Simple & affordable plans</h2>
          <Link
            onClick={analytics.viewAllFeatures}
            to="/pricing"
            className="pricing-section__link"
          >
            {t("View all features")}
          </Link>

          <Switch
            theme="light"
            cycle={cycle}
            onChange={cycle => {
              analytics.changePlanPeriod(cycle)
              setCycle(cycle)
            }}
          />
        </div>
        <div className="pricing-table-section__switcher">
          <div
            onClick={() => setTab(1)}
            className={classNames("pricing-table-section__switcher-button", {
              "pricing-table-section__switcher-button--active": tab === 1,
            })}
          >
            Single
          </div>
          <div
            onClick={() => setTab(2)}
            className={classNames("pricing-table-section__switcher-button", {
              "pricing-table-section__switcher-button--active": tab === 2,
            })}
          >
            Standard
          </div>
          <div
            onClick={() => setTab(3)}
            className={classNames("pricing-table-section__switcher-button", {
              "pricing-table-section__switcher-button--active": tab === 3,
            })}
          >
            Professional
          </div>
        </div>
      </div>
      <Elements stripe={stripePromise}>
        <div className="pricing-table-section__tariffs">
          <div
            className={classNames("wt-flex-1", {
              "display-xs-none display-sm-none display-md-none": tab !== 1,
            })}
          >
            <PricingCard
              badgeText="1 Site"
              title={t("Single")}
              responsive={false}
              description={t("The plan is perfect for a single website")}
              cost={cycle === "monthly" ? "$8" : "$7"}
              quantity={`${t("per site")} / ${
                cycle === "monthly" ? t("month") : t("month")
              }`}
              backQuantity={"/ per-site"}
              buttonText={t("Sign up now")}
              tier="Single"
              included={singleIncluded}
              withLink={true}
              cycle={cycle}
              trigger={() => {
                analytics.signUp(cycle, pickedTariff)
                setModalOpen(true)
                setPickedTariff("SINGLE_V4")
              }}
            />
          </div>
          <div
            className={classNames("wt-flex-1", {
              "display-xs-none display-sm-none display-md-none": tab !== 2,
            })}
          >
            <PricingCard
              badge="Up to 3 Sites"
              badgeText={t("Most popular 🔥")}
              yellow={true}
              title={t("Standard")}
              mostPopular
              responsive={false}
              description={t("The plan is perfect for freelancers")}
              cost={cycle === "monthly" ? "10$" : "$8"}
              quantity={`${t("per site")} / ${
                cycle === "monthly" ? t("month") : t("month")
              }`}
              backQuantity={"/ per-site"}
              buttonText={t("Sign up now")}
              tier="Standard"
              included={standardIncluded}
              withLink={true}
              cycle={cycle}
              trigger={() => {
                analytics.signUp(cycle, pickedTariff)
                setModalOpen(true)
                setPickedTariff("STANDARD_V4")
              }}
            />
          </div>
          <div
            className={classNames(
              "wt-flex-1 pricing-table-section__card-container--last",
              {
                "display-xs-none display-sm-none display-md-none": tab !== 3,
              }
            )}
          >
            <PricingCard
              badgeText={t("Up to 50 sites")}
              tier="Professional"
              included={professionalIncluded}
              title={t("Professional")}
              responsive={false}
              backQuantity={"/ per-site"}
              description={t("The plan is perfect for agencies")}
              cost={cycle === "monthly" ? "$18" : "$16"}
              quantity={`${t("per site")} / ${
                cycle === "monthly" ? t("month") : t("month")
              }`}
              buttonText={t("Sign up now")}
              withLink={true}
              cycle={cycle}
              trigger={() => {
                setModalOpen(true)
                setPickedTariff("PROFESSIONAL_V4")
                analytics.signUp(cycle, pickedTariff)
              }}
            />
          </div>
        </div>
        <ReactModal
          isOpen={isModalOpen}
          style={SignUpModalStyles}
          onRequestClose={() => setModalOpen(false)}
        >
          <SignUpForm
            tariff={pickedTariff}
            cycle={cycle.toUpperCase()}
            close={() => {
              setModalOpen(false)
            }}
          />
        </ReactModal>
      </Elements>
    </section>
  )
}

export default PricingSection
